import React, { Fragment } from 'react';

import Layout from '../components/layout';
import PasswordForgetForm from '../components/PasswordForget';
import SectionWrapper from '../components/SectionWrapper/SectionWrapper';

const PasswordForgetPage = () => (
  <Fragment>
    <h1>Password Forget</h1>
    <PasswordForgetForm />
  </Fragment>
);

export default () => (
  <Layout>
    <SectionWrapper sectionId="pw-forget">
      <PasswordForgetPage />
    </SectionWrapper>
  </Layout>
);
